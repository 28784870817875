import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/solutions/1.jpg)`}}>
                        <h1>Línea de lavado</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1"></div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>
                                Línea de lavado
                            </h1>

                            <ul style={{color: '#000000'}}>
                                <li>
                                    <a href="/linea-de-lavado-1" style={{textDecoration: "none", color: "black"}}>
                                        Línea de producción de lavado y reciclaje de botellas de PET de grado
                                        alimenticio
                                    </a>
                                </li>
                                <li>
                                    <a href="/linea-de-lavado-2" style={{textDecoration: "none", color: "black"}}>
                                        Línea de lavado de botellas de PET de grado de filamento micro denier
                                    </a>
                                </li>
                                <li>
                                    <a href="/linea-de-lavado-3" style={{textDecoration: "none", color: "black"}}>
                                        Línea de lavado de plástico HDPE / LDP
                                    </a>
                                </li>
                                <li>
                                    <a href="/linea-de-lavado-4" style={{textDecoration: "none", color: "black"}}>
                                        Sistema de reciclaje de plásticos rígidos y películas de PE
                                    </a>
                                </li>
                                <li>
                                    <a href="/linea-de-lavado-5" style={{textDecoration: "none", color: "black"}}>
                                        Unidades de limpieza funcional
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
